
import { defineComponent, ref, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
export default defineComponent({
  name: "add-permission",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const registration = Yup.object().shape({
      name: Yup.string().required("Quyền không được để trống"),
      module: Yup.string().required("Chức năng không được để trống"),
    });
    const onSubmitRegister = (values) => {
      // Activate indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");
      // Send login request
      store
        .dispatch("ACTION_ADD_PERMISSION", values)
        .then(() => {
          Swal.fire({
            text: "Tạo mới thành công",
            icon: "success",
            buttonsStyling: false,
            showCancelButton: true,
            confirmButtonText: "Danh sách",
            cancelButtonText: "Đóng",
            customClass: {
              confirmButton: "btn fw-bold btn-light-success",
              cancelButton: "btn fw-bold btn-light-primary",
            },
          }).then(async function (result) {
            // Go to page after successfully login
            if (result.isConfirmed) {
              await router.push({ name: "permissions" });
            } else if (result.isDismissed) {
              let data = await store.getters["currentPermission"];
              await router.push({
                name: "permissions-edit",
                params: { id: data.data._id },
              });
            }
          });
        })
        .catch(() => {
          Swal.fire({
            text: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Thử lại!",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });
      submitButton.value?.removeAttribute("data-kt-indicator");
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Thêm mới quyền", ["Người dùng"]);
      MenuComponent.reinitialization();
    });
    return {
      registration,
      onSubmitRegister,
      submitButton,
    };
  },
});
